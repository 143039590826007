import axios from 'axios';
import { Nullable } from 'provider/AppProvider/AppProvider.interface';
import { SubTopic } from 'services/api/topics';

export const getHeaders = (token?: string, method?: string) => {
  if (
    (token ?? '') !== '' &&
    token !== 'undefined' &&
    typeof token !== undefined
  ) {
    return {
      headers: {
        'WEB-APP-REQUEST': 'true',
        'Authorization': `Bearer ${String(token) ?? ''}`,
        ...(['POST', 'PATCH', 'PUT'].includes(method ?? 'GET')
          ? { 'Content-Type': 'application/json' }
          : null),
      },
    };
  }

  return {
    headers: {
      'WEB-APP-REQUEST': 'true',
      ...(['POST', 'PATCH', 'PUT'].includes(method ?? 'GET')
        ? { 'Content-Type': 'application/json' }
        : null),
    },
  };
};

export const request = async (
  url: string,
  method: string,
  token?: string,
  body?: string,
) => {
  const requestOptions = {
    method: method ?? 'GET',
    ...(getHeaders(token, method) ?? null),
    ...(method === 'POST'
      ? {
          body,
        }
      : null),
  };

  const response = await fetch(url, requestOptions);

  return response;
};

export const axiosRequest = async (
  url: string,
  method: string,
  token?: string,
  body?: string,
) => {
  const requestOptions = {
    url,
    method: method ?? 'GET',
    ...(getHeaders(token, method) ?? null),
    ...(method === 'POST'
      ? {
          body,
        }
      : null),
  };

  return await axios(requestOptions);
};

export const patchRequest = async (
  url: string,
  method: string,
  token?: string,
  body?: string,
) => {
  const requestOptions = {
    method: 'PATCH',
    ...(getHeaders(token, method) ?? null),
    ...{
      body,
    },
  };

  return await fetch(url, requestOptions);
};

export interface GetSomethingResponse {
  [key: string]: string;
}

const callEndpoint = async (
  url: string | undefined,
  method: string,
): Promise<GetSomethingResponse> => ({ url: String(url), method });

export const GetSomething = async (): Promise<GetSomethingResponse> => {
  return await callEndpoint(`${String(process.env.REACT_APP_API)}/apps`, 'GET');
};

export const getWelcomeData = async (
  uuid: string | number | Nullable<string> | undefined,
  token?: string,
) => {
  if (typeof uuid === 'undefined' || uuid === null) return null;

  const response = await request(
    // eslint-disable-next-line prettier/prettier
    `${String(process.env.REACT_APP_API)}/apps/${
      uuid ?? ''
    }/welcome_screens?web=true`,
    'GET',
    token,
  );

  if (!response.ok) {
    throw new Error('Problem fetching data');
  }

  const data = await response.json();
  return data;
};

export const getAppDataByUUID = async (
  uuid: string | number | Nullable<string> | undefined,
) => {
  if (typeof uuid === 'undefined' || uuid === null) return null;

  const response = await request(
    // eslint-disable-next-line prettier/prettier
    `${String(process.env.REACT_APP_API)}/apps/${uuid ?? ''}`,
    'GET',
  );

  if (!response.ok) {
    throw new Error('Problem fetching data');
  }

  const data = await response.json();
  return data;
};

export const getUserDataByUsername = async (
  username: string | Nullable<string> | undefined,
  token?: string,
) => {
  const response = await request(
    `${String(process.env.REACT_APP_API)}/apps/web/${username ?? ''}`,
    'GET',
    token,
  );

  // Catch non-existent username
  if (response.status === 404) {
    return {
      data: {
        status: 404,
        statusText: 'username not found',
      },
    };
  }

  if (!response.ok) {
    throw new Error('Problem fetching data');
  }

  const data = await response.json();
  return data;
};

export const getUserDataByDomain = async (
  domain: string | Nullable<string> | undefined,
  token?: string,
) => {
  const response = await request(
    `${String(process.env.REACT_APP_API)}/apps/domain/${domain ?? ''}`,
    'GET',
    token,
  );

  // Catch non-existent domain name
  if (response.status === 404) {
    return {
      data: {
        status: 404,
        statusText: 'domain not found',
      },
    };
  }

  if (!response.ok) {
    throw new Error('Problem fetching data');
  }

  const data = await response.json();
  return data;
};

export const getFeaturedContent =
  (uuid: string, topic_id: string, token?: string) =>
  async (): Promise<SubTopic[]> => {
    const path = `${String(
      process.env.REACT_APP_API,
    )}/apps/${uuid}/featured_content?top=false&topic_id=${topic_id}`;

    const response = await request(path, 'GET', token);

    const data = await response.json();
    return data?.data;
  };

export const getPosts =
  (uuid: string, token?: string) => async (): Promise<SubTopic[]> => {
    const response = await request(
      `${String(process.env.REACT_APP_API)}/apps/${uuid}/content/tips`,
      'GET',
      token,
    );

    const data = await response.json();
    return data?.data;
  };

export const signUpMember =
  (uuid: string) => async (payload: Record<string, unknown>) => {
    const response = await axios.post(
      `${String(process.env.REACT_APP_API)}/apps/${uuid}/members`,
      payload,
    );

    return response.data;
  };

export const getUnlockScreenData = async (
  uuid?: string | null,
  token?: string,
  body?: string,
) => {
  const response = await request(
    `${String(process.env.REACT_APP_API)}/apps/${uuid ?? ''}/track_info`,
    'GET',
    token,
    body,
  );

  const result = response.json();

  return await result;
};
