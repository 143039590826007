import { FC, useState } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useDefaultColorValue } from 'services/queries/app';
import { useAppContext } from 'provider/AppProvider/AppProvider';
import { ISubmenuProps } from '../Navbar.interface';
import { StyledNavbar } from '../Navbar.styles';

export interface IMenuItemProps {
  name: string;
  path: string;
  submenu: ISubmenuProps[];
  handleCloseNavbar: () => void;
}

const MenuItem: FC<IMenuItemProps> = ({
  name,
  path,
  submenu,
  handleCloseNavbar,
}) => {
  const { baseURL } = useAppContext();
  const colors = useDefaultColorValue();
  const [svgColor, setSvgColor] = useState(colors.dark);

  return (
    <li className="nav-item">
      <StyledNavbar
        colors={colors}
        className="px-3 py-2 flex items-center uppercase font-semibold text-[24px] lg:text-[22px] leading-snug"
        to={`${String(baseURL)}${path}`}
      >
        {name === 'Home' ? (
          <div className="flex items-center justify-center cursor-pointer">
            <IoHomeOutline
              size={32}
              color={svgColor}
              onMouseEnter={() => setSvgColor(colors.medium)}
              onMouseLeave={() => setSvgColor(colors.dark)}
              onClick={handleCloseNavbar}
            />
          </div>
        ) : (
          <span className="ml-2 whitespace-nowrap">{name}</span>
        )}

        {/* @TODO: Create a new component for dropdown menu item */}
        {submenu?.length > 0 ? <IoMdArrowDropdown size={12} /> : null}
      </StyledNavbar>
    </li>
  );
};

export default MenuItem;
