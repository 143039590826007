/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import FallbackImage from 'assets/images/no-picture.png';
import InnerHTML from 'dangerously-set-html-content';
import { generateUrl } from 'utils/url';
import { ChildrenProps, IAppContext } from './AppProvider.interface';

/* eslint-disable camelcase */
// @TODO: Default values should always be empty
export const appDefaultContext: Partial<IAppContext> = {
  isLoading: false,
  uuid: null,
  username: 'actionera',
  name: 'Actionera Demo',
  isSubscribed: false,
  userRole: 'guest',
  setup: {
    head_tag: '',
    body_tag: '',
    icon: 'https://web.actionera.com/actionera.ico',
    header: {
      tagline: 'Experts Sharing Their Knowledge & Ideas',
      logo: {
        logo_url: 'https://i.ibb.co/z7ctLX1/Logo.png',
        logo_text: 'Xpert Insights',
      },
      menu: {
        enabled_modules: ['topics', 'directories', 'tips'],
        label_overrides: {
          directories: 'Directories',
          tips: 'Tips/Posts',
          topics: 'Topics',
        },
      },
    },
    colors: {
      light: '#F2EDE1',
      medium: '#107F87',
      dark: '#204166',
      grey: '#888888',
      dark_blue: '#26486B',
      orange: '#CE7F34',
      navy_blue: '#35406D',
      white: '#FFFFFF',
      silky_white: '#EEEBE2',
      black: '000000',
    },
    fonts: {
      header: 'Oswald',
      body: 'Times New Roman',
      app: 'SegoeUI',
    },
    web: true,
  },
  about: {
    title: 'Actionera Demo',
    video_url:
      'https://api.actionera.com/media/6845658c-9271-444f-998e-a122c977d18b',
    image_url: '',
    description: 'This is a demo app for the actionera platform',
    action_bar_text: '',
    action_bar_url: '',
    call_to_action_url: '/#',
    privacy_policy_url: '',
    platform_version: '9.0',
  },
  profile: {
    name: 'Actionera',
    title: 'Technology and Training',
    business_name: 'Content Delivery Technologies',
    profile_image_url:
      'https://media.istockphoto.com/photos/eyes-of-the-tiger-picture-id490989354?k=20&m=490989354&s=612x612&w=0&h=KhErA93o29Ovl4T2-C0-RetGGEVgeSuGv8FryDBNzyw=',
    email: 'hello@actionera.com',
    web_bio:
      "Actionera is a unique app development company developing mobile app platforms for small businesses, experts and entrepreneurs to use a high-end mobile app technology to share their business knowledge, products, services, attract new clients and serve existing clients. The challenges with using the mobile app world to market your business is the cost to develop a truly 'effective' dynamic mobile app that people will use and that has all the elements that make it a powerful marketing tool. To do this a business can expect to spend 10's of thousands of dollars. We also created an individual business platform called the 'Actionera Expert App Platform.' This is high end technology that provides a place for businesses to share content, market and promote products and services and support existing customers. A link to our website is at the bottom of this screen and if you want to explore creating your own mobile app then click the button to Schedule a Call Here, below.",
    bio: "Actionera is a unique app development company developing mobile app platforms for small businesses, experts and entrepreneurs to use a high-end mobile app technology to share their business knowledge, products, services, attract new clients and serve existing clients. The challenges with using the mobile app world to market your business is the cost to develop a truly 'effective' dynamic mobile app that people will use and that has all the elements that make it a powerful marketing tool. To do this a business can expect to spend 10's of thousands of dollars. We also created an individual business platform called the 'Actionera Expert App Platform.' This is high end technology that provides a place for businesses to share content, market and promote products and services and support existing customers. A link to our website is at the bottom of this screen and if you want to explore creating your own mobile app then click the button to Schedule a Call Here, below.",
    website_url: 'www.actionera.com',
    facebook_url: 'facebook.com/liveyourlist',
    twitter_url: 'twitter.com/liveyourlist',
    instagram_url: 'www.instagram.com/liveyourlist',
    youtube_url: 'www.youtube.com/livingyourlist',
    linkedin_url: 'www.linkedin.com/liveyourlist',
    action_bar_text: 'Get the Live Your List Planner',
    action_bar_url: 'www.liveyourlist.com',
  },
  config: {
    defaultLabels: {
      directories: 'Directories',
      topics: 'Topics',
      offers: 'Featured Resource  ',
      action_lists: 'ACTIONLISTS',
      tips: 'Blog Posts',
      videos: 'Videos',
      articles: 'Articles',
      lessons: 'Lessons',
      audios: 'Audios',
      journals: 'ACTIONJOURNAL',
      questions: 'Q&A',
      galleries: 'Galleries',
      resources: 'Resources',
      products: 'Products',
      courses: 'Courses',
      events: 'ACTIONCALENDAR',
      virtual_events: 'ACTIONEVENTS',
      summits: 'ACTIONSUMMITS',
      documents: 'Documents',
      track_now: 'UNLOCK CODE',
      enterprise: 'Enterprise Directory',
    },
  },
  title: '',
  description: '',
  baseURL: '',
  fallBackImage: FallbackImage,
};
/* eslint-enable camelcase */

export const AppContext = React.createContext<IAppContext>(
  appDefaultContext as IAppContext,
);

const AppProvider = ({ children }: ChildrenProps) => {
  // @TODO: should be moved to a hook that uses react-query to fetch app data
  const [isLoading, setIsLoading] = React.useState(appDefaultContext.isLoading);
  const [uuid, setUuid] = React.useState(appDefaultContext.uuid);
  const [username, setUsername] = React.useState(appDefaultContext.username);
  const [name, setName] = React.useState(appDefaultContext.name);
  const [setup, setSetup] = React.useState(appDefaultContext.setup);
  const [about, setAbout] = React.useState(appDefaultContext.about);
  const [profile, setProfile] = React.useState(appDefaultContext.profile);
  const [hero, setHero] = React.useState(appDefaultContext.hero);
  const [colors, setColors] = React.useState(appDefaultContext.colors);
  const [isSubscribed, setIsSubscribed] = React.useState(
    appDefaultContext.isSubscribed,
  );
  const [userRole, setUserRole] = React.useState(appDefaultContext.userRole);
  const [config, setConfig] = React.useState(appDefaultContext.config);
  const [baseURL, setBaseURL] = React.useState(appDefaultContext.baseURL);
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');

  const value = React.useMemo(
    () => ({
      isLoading,
      setIsLoading,
      uuid,
      setUuid,
      username,
      setUsername,
      name,
      setName,
      setup,
      setSetup,
      about,
      setAbout,
      profile,
      setProfile,
      hero,
      setHero,
      colors,
      setColors,
      isSubscribed,
      setIsSubscribed,
      config,
      setConfig,
      baseURL,
      setBaseURL,
      fallBackImage: setup?.icon ? generateUrl(setup?.icon) : FallbackImage,
      userRole,
      setUserRole,
      title,
      description,
      setTitle,
      setDescription,
    }),
    [
      uuid,
      username,
      name,
      setup,
      about,
      profile,
      hero,
      colors,
      isSubscribed,
      baseURL,
      title,
      description,
    ],
  );

  return (
    <AppContext.Provider value={value as IAppContext}>
      {setup?.body_tag && (
        <InnerHTML
          html={setup.body_tag}
          className="text-gray-700 text-base mb-0 whitespace-pre-wrap w-full"
        />
      )}
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
export const useAppContext = () => React.useContext(AppContext);
